// Import necessary React modules
import React, { Component, ReactNode } from 'react';

// Define the props for the ErrorBoundary component
interface ErrorBoundaryProps {
  children: ReactNode;
  fallback?: ReactNode;
}

// Define the state for the ErrorBoundary component
interface ErrorBoundaryState {
  hasError: boolean;
  error: Error | null;
  errorInfo: React.ErrorInfo | null;
}

// Define the ErrorBoundary component
class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null,
    };
  }

  // Lifecycle method to catch errors in child components
  static getDerivedStateFromError(error: Error): Partial<ErrorBoundaryState> {
    return { hasError: true };
  }

  // Log the error details
  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    this.setState({ error, errorInfo });
    // console.error("ErrorBoundary caught an error:", error, errorInfo);
  }

  render() {
    const { hasError, error, errorInfo } = this.state;
    const { fallback, children } = this.props;

    if (hasError) {
      // Render the fallback UI if an error occurs
      return (
        fallback || (
          <div style={{ color: 'red', padding: '20px', border: '1px solid red' }}>
            <h2>Something went wrong.</h2>
            {error && <p>{error.toString()}</p>}
            {errorInfo && <details style={{ whiteSpace: 'pre-wrap' }}>{errorInfo.componentStack}</details>}
          </div>
        )
      );
    }

    // Render children if no error occurred
    return children;
  }
}

export default ErrorBoundary;
