import { useEffect, useState } from "react";
import store, { useAppSelector } from "../../../Redux/Store";
import "./HistoryPage.css";
import { useParams } from "react-router-dom";
import adminService from "../../../Services/Admin";
import linesService from "../../../Services/Lines";
import AdminLineCard from "../AdminLineCard/AdminLineCard";
import Loader from "../../Loader";
import HelpPage from "../HelpPage/HelpPage";

function HistoryPage(): JSX.Element {

    const adminData = useAppSelector(state => (state.authState.admin));
    const [linesHistory, setlinesHistory] = useState(store.getState().linesState.linesHistory);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const params = useParams();
    useEffect(() => {
        if (!adminData) adminService.getAdminByIdAsync(params._id);
        if ((linesHistory.length === 0) || (linesHistory[0]?.admin_id !== adminData._id)) {
            linesService.getLinesHistoryAsync(adminData._id)
                .then(lines => {
                    setIsLoading(false);
                    setlinesHistory(lines);
                });
        }
        else setIsLoading(false);
    }, [])
    return (
        <div className="HistoryPage gradient-bg">
            {/* {isLoading ? <Loader /> : linesHistory?.length > 0 ? <>
                <div className="list-container">
                    {[...linesHistory].sort((a, b) => (+a.timestamp) - (+b.timestamp)).map(l => <AdminLineCard key={l._id} line={l} />)}
                </div>
            </>
                :
                <h2 className="lines-list-h2"> אין הסטוריה זמינה  </h2>
            } */}
            <HelpPage />

        </div>
    );
}

export default HistoryPage;
