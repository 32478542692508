import { AppBar, Avatar, Box, Button, Container, IconButton, Menu, MenuItem, Slide, Toolbar, Tooltip, Typography, useScrollTrigger } from '@mui/material';
import * as React from 'react';
import HeaderDrawer from './HeaderDrawer';
import { Link, useParams } from 'react-router-dom';
import { useAppSelector } from '../../../Redux/Store';
import globals from '../../../Services/Globals';
import adminService from '../../../Services/Admin';


const userPagesList = [
    {
        name: "חשבון",
        url: "account"
    },
    {
        name: "עזרה",
        url: "help"
    },
    {
        name: "התנתקות",
        url: "logout"
    }

];
const pagesList = [
    {
        name: "רשימת תורים",
        url: "lines"
    },
    {
        name: "זמנים וחופשות",
        url: "schedule"
    },
    {
        name: "הסטוריה",
        url: "history"
    },
    {
        name: "הגדרות",
        url: "settings"
    }

]

interface HideOnScrollProps {
    children: React.ReactElement;
}

function HideOnScroll(props: HideOnScrollProps) {
    const trigger = useScrollTrigger();
    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {props.children}
        </Slide>
    );
}

export default function AdminHeader(): JSX.Element {
    const adminData = useAppSelector(state => (state.authState.admin));
    const params = useParams();

    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    React.useEffect(() => {
        if (!adminData) adminService.getAdminByIdAsync(params._id);
    }, []);

    return <HideOnScroll>
        <AppBar position="fixed" color='warning'>
            <Container maxWidth="xl" disableGutters>
                <Toolbar disableGutters>
                    <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}>
                        <Tooltip title={<span style={{ fontSize: 'small' }}>החשבון שלך</span>} followCursor arrow
                            slotProps={{
                                popper: {
                                    modifiers: [
                                        {
                                            name: 'offset',
                                            options: {
                                                offset: [0, 25],
                                            },
                                        },
                                    ],
                                },
                            }}
                        >
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                <Avatar alt="Account" src={globals.imagesUrl + "/" + adminData.logoImgName} sx={{ width: '80px', height: '80px' }} />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            {userPagesList.map((page) => (
                                <Link to={page.url} key={page.name}>
                                    <MenuItem dir='rtl' onClick={handleCloseUserMenu}>
                                        <Typography>{page.name}</Typography>
                                    </MenuItem>
                                </Link>
                            ))}
                        </Menu>
                    </Box>

                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <HeaderDrawer />
                    </Box>
                    <Typography
                        component="a"
                        href="/"
                        sx={{
                            ml: 4,
                            display: { xs: 'flex', md: 'none' },
                            flexGrow: 1,
                        }}
                    >
                        <img src={globals.imagesUrl + "/" + adminData.logoImgName} alt="logo" width="60vw" />
                    </Typography>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        {pagesList.map((page) => (
                            <Link key={page.name} to={page.url}>
                                <Button
                                    onClick={handleCloseNavMenu}
                                    sx={{ my: 2, mx: 2, color: 'white', display: 'block', fontSize: 'medium' }}
                                >
                                    {page.name}
                                </Button>
                            </Link>
                        ))}
                    </Box>

                    {/* <Box sx={{ flexGrow: 0 }}>
                        <Tooltip title="Open settings">
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            {settings.map((setting) => (
                                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                                    <Typography textAlign="center">{setting}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box> */}
                </Toolbar>
            </Container>
        </AppBar>
    </HideOnScroll>
}