import React from 'react';
import { useDraggable } from '@dnd-kit/core';

interface Props {
    component: JSX.Element;
    id: string;
}

function DraggableComponent(props: Props): JSX.Element {
    const { attributes, listeners, setNodeRef, transform } = useDraggable({
        id: props.id,
    });
    const style = transform ? {
        zIndex: "100", transition: "none", transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`, cursor: "grabbing"
    } : undefined;


    return (
        <button ref={setNodeRef} style={{ cursor: 'grab', transition: "transform 0.3s ease,display 0.3s ease", padding: 0, margin: '1rem', borderWidth: '0', ...style, touchAction: 'manipulation', position: "relative" }} {...listeners} {...attributes}>
            {props.component}
        </button>
    );
}
export default DraggableComponent;