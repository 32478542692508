import { Navigate } from "react-router-dom";
import store from "../../../Redux/Store";
import { useEffect } from "react";
import notify from "../../../Services/Notify";
import { adminLoggedOut } from "../../../Redux/Reducers/auth.slice";

function Logout(): JSX.Element {
    const _id = store.getState().authState.admin._id;
    useEffect(() => {
        return () => {
            store.dispatch(adminLoggedOut());
            localStorage.removeItem("ezLinesUser");
            notify.custom("הינך מחובר כאורח");
        }
    }, [])
    return <Navigate to={"/" + _id} />
}

export default Logout;
