import { Navigate, useParams } from "react-router-dom";
import store from "../Redux/Store";

interface protectedRouteProps {
    redirectPath: string;
    protectedComponent: JSX.Element;
}


export default function ProtectedRoute(props: protectedRouteProps) {
    const params = useParams();
    const _id = params._id;
    const isAuthenticated = store.getState().authState.admin && (_id === store.getState().authState.admin._id);
    if (!isAuthenticated) return <Navigate to={props.redirectPath} replace />
    return props.protectedComponent;
}