import ParticlesBg from "particles-bg";
import { useEffect, useState } from "react";
import { Fade } from "react-awesome-reveal";
import { Link, useParams } from "react-router-dom";
import { useAppSelector } from "../Redux/Store";
import adminService from "../Services/Admin";
import globals from "../Services/Globals";

function Header() {
  const [index, setIndex] = useState(0);
  const params = useParams();
  const admin = useAppSelector(state => (state.linesState.currentAdmin || state.authState.admin));


  useEffect(() => {
    let interval: NodeJS.Timer;
    if (!admin || (admin._id !== params._id)) adminService.getAdminByIdAsync(params._id);
    if (admin && admin.descriptions.length > 1) {
      interval = setInterval(() => {
        setIndex(prev => prev === (admin.descriptions.length - 1) ? 0 : prev + 1);
      }, 5000);
    }
    return () => { if (interval) clearInterval((interval)) };
  }, [])

  return (
    <header id="header">
      <ParticlesBg type="circle" color="#cccccc" bg={true} />
      <nav id="nav-wrap">
        <a className="mobile-btn" href="#nav-wrap" title="Show navigation">
          Show navigation
        </a>
        <a className="mobile-btn" href="#nav" title="Hide navigation">
          Hide navigation
        </a>

        <ul id="nav" className="nav">
          <li className="current">
            <a className="smoothscroll" href="#header">
              דף הבית
            </a>
          </li>
          <li>
            <a className="smoothscroll" href="#about">
              קצת עליי
            </a>
          </li>
          <li>
            <a className="smoothscroll" href="#portfolio">
              תיק עבודות
            </a>
          </li>
          <li>
            <a className="smoothscroll" href="#LinePicker">
              זימון תורים
            </a>
          </li>
          <li>
            <a className="smoothscroll" href="#contact">
              צור קשר
            </a>
          </li>
          <li>
            <Link style={{ fontSize: "large" }} to="/auth/login">
              התחברות
            </Link>
          </li>
        </ul>
      </nav>

      <div className="row banner">
        <div id="header-bg" style={{ backgroundImage: `url(${globals.imagesUrl + "/" + admin?.logoImgName})` }}></div>
        <div className="banner-text" >
          <br />
          <Fade triggerOnce direction="down" duration={1500} key={index}>
            <h3 className="description">
              {admin && admin.descriptions[index]}
            </h3>
          </Fade>
          <hr />
          <Fade triggerOnce direction="down" duration={2000}>
            <ul className="social">
              <a href="#LinePicker" className="button btn project-btn smoothscroll gradient-bg">
                להזמנת תור<i className="fa fa-book"></i>
              </a>
            </ul>
          </Fade>
        </div>
      </div>

      <p className="scrolldown">
        <a className="smoothscroll" href="#about">
          <i className="fa fa-chevron-down"></i>
        </a>
      </p>
    </header>
  );
}


export default Header;
