import { useEffect, useState } from "react";
import "./ScheduleAndVaca.css";
import AdminModel from "../../../Models/AdminModel";
import store from "../../../Redux/Store";
import { useParams } from "react-router-dom";
import adminService from "../../../Services/Admin";
import { Button, FormControl, Switch, TextField } from "@mui/material";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";

function ScheduleAndVaca(): JSX.Element {
    const daysMap = ["ראשון", "שני", "שלישי", "רביעי", "חמישי", "שישי", "שבת"];
    const [adminData, setAdminData] = useState<AdminModel>(store.getState().authState.admin);
    const [vacationStart, setVacationStart] = useState<number>();
    const [vacationEnd, setVacationEnd] = useState<number>();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const params = useParams();


    useEffect(() => {
        if (!adminData || (adminData._id !== params._id)) adminService.getAdminByIdAsync(params._id)
            .then(res => setAdminData(res));
    }, [])

    const removeVacation = (vacation: string) => {
        const index = adminData.vacations.findIndex(v => v === vacation);
        const newArr = [...adminData.vacations];
        newArr.splice(index, 1);
        setAdminData(prev => ({ ...prev, vacations: newArr }));
    }
    const addVacation = () => {
        // const index = adminData.vacations.findIndex(v => v === vacation);
        const newArr = [...adminData.vacations];
        newArr.push(vacationStart + "-" + vacationEnd);
        setAdminData(prev => ({ ...prev, vacations: newArr }));
    }

    const handleHoursChange = (value: string, arrIndex: number, startEndIndex: number) => {
        const newArr = [...adminData.workingDays];
        let elementToChange = newArr[arrIndex];
        const elementArr = elementToChange.split("-");
        elementArr[startEndIndex] = value;
        elementToChange = elementArr.join("-");
        newArr[arrIndex] = elementToChange;
        setAdminData(prev => ({ ...prev, workingDays: newArr }));
    }

    const handleDayOnOff = (index: number) => {

        if (adminData.workingDays[index] !== null) {
            const newArr = [...adminData.workingDays];
            newArr[index] = null;
            return setAdminData(prev => ({ ...prev, workingDays: newArr }));
        }
        const defaultTime = "10:00-18:00";
        const newArr = [...adminData.workingDays];
        newArr[index] = defaultTime;
        setAdminData(prev => ({ ...prev, workingDays: newArr }));
    }

    const saveChanges = async () => {
        setIsLoading(true);
        await adminService.updateAdminAsync(adminData);
        setIsLoading(false);
    }

    return (
        <div className="ScheduleAndVaca">
            <h4> שעות עבודה :</h4>
            <div className="adminWorkingDays">
                {daysMap.map((d, i) => {
                    let startDate = new Date().setHours(10, 0);
                    let endDate = new Date().setHours(18, 0);
                    if (adminData.workingDays[i]) {
                        const startHours = adminData.workingDays[i].split("-")[0].split(":")[0];
                        const startMinutes = adminData.workingDays[i].split("-")[0].split(":")[1];
                        startDate = new Date().setHours(+startHours, +startMinutes);
                        const endHours = adminData.workingDays[i].split("-")[1].split(":")[0];
                        const endMinutes = adminData.workingDays[i].split("-")[1].split(":")[1];
                        endDate = new Date().setHours(+endHours, +endMinutes);
                    }
                    return <span key={i}>
                        <h6>יום {d} :</h6>
                        <div className="dayDiv">
                            <Switch checked={adminData.workingDays[i] ? true : false} onChange={event => { handleDayOnOff(i) }} />
                            <TimePicker disabled={!adminData.workingDays[i]} sx={{ maxWidth: "20vw" }} value={dayjs(startDate)}
                                onAccept={(val: Dayjs) => handleHoursChange(val.hour() + ":" + (val.minute().toLocaleString().length === 1 ? "0" + val.minute().toLocaleString() : val.minute()), i, 0)}
                            />
                            <span style={{ fontSize: 60 }}> - </span>
                            <TimePicker disabled={!adminData.workingDays[i]} sx={{ maxWidth: "20vw" }} value={dayjs(endDate)}
                                onAccept={(val: Dayjs) => handleHoursChange(val.hour() + ":" + (val.minute().toLocaleString().length === 1 ? "0" + val.minute().toLocaleString() : val.minute()), i, 1)}
                            />
                        </div>
                    </span>
                })}
            </div>
            <h4>זמן תור :</h4>
            <FormControl sx={{ mb: 2 }}>
                <TextField value={adminData.minutesPerLine} onChange={event => setAdminData(prev => ({ ...prev, minutesPerLine: +event.currentTarget.value }))} label="מספר דקות לכל תור " dir="ltr" margin="dense" required type="number" id="minutes-input" variant="outlined" />
            </FormControl>
            <div className="vacations-div">
                <h4>חופשות  :</h4>
                {adminData.vacations.map(v => {
                    const start = v.split("-")[0];
                    const end = v.split("-")[1];

                    return <div className="vacation-line" key={v}>
                        <DatePicker disabled defaultValue={dayjs(new Date(+start).getTime())} sx={{ direction: "ltr" }} label="התחלה" />
                        <DatePicker disabled defaultValue={dayjs(new Date(+end).getTime())} sx={{ direction: "ltr" }} label="סיום" />
                        <Button onClick={() => removeVacation(v)} sx={{ margin: "1rem", padding: '0.5rem 1rem 0.5rem 1rem' }} type="submit" variant="contained" color="error">מחיקה</Button>
                    </div>

                })}
                <strong>חופשה חדשה</strong>
                <div className="vacation-line" >
                    <DatePicker
                        slotProps={{
                            dialog: { closeAfterTransition: true }
                        }}
                        dayOfWeekFormatter={(day: string) => {
                            switch (day) {
                                case "א׳": return "ראשון"
                                case "ב׳": return "שני"
                                case "ג׳": return "שלישי"
                                case "ד׳": return "רביעי"
                                case "ה׳": return "חמישי"
                                case "ו": return "שישי"
                                case "ש׳": return "שבת"
                            }
                        }}
                        disablePast
                        closeOnSelect
                        onChange={(val: Dayjs) => setVacationStart(new Date(val.valueOf()).getTime())}
                        sx={{
                            direction: 'ltr',
                            //  '.MuiDatePicker-root': {
                            //     color: '#000000',
                            //     borderRadius: '18px',
                            //     borderWidth: '1px',
                            //     borderColor: '#c0d6e4',
                            //     border: '2px solid #c0d6e4',
                            //     backgroundColor: '#F0F8FF',
                            //     direction: 'rtl'
                            // },
                            // '.MuiPickersCalendarHeader-root': { direction: 'ltr' }
                        }}
                        label="התחלה"
                    />
                    <DatePicker
                        slotProps={{
                            dialog: { closeAfterTransition: true }
                        }}
                        dayOfWeekFormatter={(day: string) => {
                            switch (day) {
                                case "א׳": return "ראשון"
                                case "ב׳": return "שני"
                                case "ג׳": return "שלישי"
                                case "ד׳": return "רביעי"
                                case "ה׳": return "חמישי"
                                case "ו": return "שישי"
                                case "ש׳": return "שבת"
                            }
                        }}
                        disablePast
                        closeOnSelect
                        onChange={(val: Dayjs) => setVacationEnd(new Date(val.valueOf()).getTime())}
                        sx={{ direction: "ltr" }}
                        label="סיום"
                    />
                    <Button disabled={!vacationStart || !vacationEnd || (vacationStart > vacationEnd)} onClick={addVacation} sx={{ margin: "1rem", padding: '0.5rem 1rem 0.5rem 1rem' }} type="submit" variant="contained" color="success">הוספה</Button>
                </div>
                <div className="form-actions-div">
                    <Button disabled={isLoading} onClick={saveChanges} sx={{ margin: "1rem", padding: '0.5rem 2rem 0.5rem 2rem', borderRadius: '20px' }} type="submit" variant="contained" color="success">שמירה</Button>
                    <Button onClick={() => adminService.getAdminByIdAsync(params._id).then(res => setAdminData(res))} sx={{ margin: "1rem", padding: '0.5rem 2rem 0.5rem 2rem', borderRadius: '20px' }} type="submit" variant="contained" color="warning">ביטול</Button>
                </div>
            </div>
        </div>
    );
}

export default ScheduleAndVaca;
