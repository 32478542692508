import "./HelpPage.css";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

function HelpPage(): JSX.Element {
    return (
        <div className="HelpPage">
            <h1> <WarningAmberIcon fontSize="inherit" /></h1>
            <h1>דף זה נמצא בשיפוצים</h1>
        </div>
    );
}

export default HelpPage;
