import "./ChangePassword.css";
import * as React from 'react';
import { styled, css } from '@mui/system';
import { Modal as BaseModal } from '@mui/base/Modal';
import Fade from '@mui/material/Fade';
import { Button } from '@mui/base/Button';
import { TextField, Button as Mbutton } from "@mui/material";
import notify from "../../../../Services/Notify";
import usersService from "../../../../Services/Users";

function ChangePassword(props: { admin_id: string }) {
    const [open, setOpen] = React.useState(false);
    const [oldPass, setOldPass] = React.useState("");
    const [newPass, setNewPass] = React.useState("");
    const [passConfirm, setPassConfirm] = React.useState("");
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setOldPass("");
        setNewPass("");
        setPassConfirm("");
    }

    const handlePassChange = async () => {
        if (newPass !== passConfirm) return notify.error("אימות סיסמא לא זהה לסיסמא החדשה");
        if (newPass === oldPass) return notify.error("אנא בחר סיסמא שונה מהסיסמא הנוכחית");
        setIsLoading(true);
        const res = await usersService.changePasswordAsync(props.admin_id, oldPass, newPass);
        setIsLoading(false);
        if (res) handleClose();
    }

    return (
        <div className="ChangePassword">
            <TriggerButton onClick={handleOpen}>שינוי סיסמא</TriggerButton>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: StyledBackdrop }}
            >
                <Fade in={open}>
                    <ModalContent sx={style}>
                        <h2 id="transition-modal-title" className="modal-title">
                            בחר סיסמא חדשה :
                        </h2>
                        <div id="transition-modal-description" className="modal-description">
                            <TextField
                                size="small"
                                value={oldPass}
                                onChange={(event) => setOldPass(event.target.value)}
                                label="סיסמא נוכחית"
                                type="password"
                                dir="ltr"
                                margin="normal"
                                fullWidth
                                variant="outlined"
                            />
                            <TextField
                                size="small"
                                value={newPass}
                                onChange={(event) => setNewPass(event.target.value)}
                                label="סיסמא חדשה"
                                type="password"
                                dir="ltr"
                                margin="normal"
                                fullWidth
                                variant="outlined"
                                helperText={newPass.length < 6 ? "מינימום 6 תווים" : ""}
                            />
                            <TextField
                                size="small"
                                value={passConfirm}
                                onChange={(event) => setPassConfirm(event.target.value)}
                                label="אימות סיסמא חדשה"
                                type="password"
                                dir="ltr"
                                margin="normal"
                                fullWidth
                                variant="outlined"
                            />
                            <div className="form-actions-div">
                                <Mbutton
                                    fullWidth
                                    disabled={isLoading || (newPass.length < 6) || (passConfirm.length < 6)}
                                    onClick={handlePassChange}
                                    sx={{ margin: "1rem", padding: '0.3rem 2rem', borderRadius: '20px' }}
                                    type="submit"
                                    variant="contained"
                                    color="success"
                                >
                                    שמירה
                                </Mbutton>
                                <Mbutton
                                    onClick={handleClose}
                                    fullWidth
                                    sx={{ margin: "1rem", padding: '0.3rem 2rem', borderRadius: '20px' }}
                                    type="submit"
                                    variant="contained"
                                    color="warning"
                                >
                                    ביטול
                                </Mbutton>
                            </div>
                        </div>
                    </ModalContent>
                </Fade>
            </Modal>
        </div>
    );
}

const Backdrop = React.forwardRef<HTMLDivElement, { open?: boolean }>(
    (props, ref) => {
        const { open, ...other } = props;
        return (
            <Fade in={open}>
                <div ref={ref} {...other} />
            </Fade>
        );
    },
);

const blue = {
    200: '#99CCFF',
    300: '#66B2FF',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
    700: '#0066CC',
};

const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
};

const Modal = styled(BaseModal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 350,
    direction: "rtl"
};

const ModalContent = styled('div')(
    ({ theme }) => css`
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 500;
    text-align: start;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-content:center;
    gap: 8px;
    overflow: hidden;
    background-color: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border-radius: 8px;
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    box-shadow: 0 4px 12px
      ${theme.palette.mode === 'dark' ? 'rgb(0 0 0 / 0.5)' : 'rgb(0 0 0 / 0.2)'};
    padding: 1rem 2rem;
    color: ${theme.palette.mode === 'dark' ? grey[50] : grey[900]};

    & .modal-title {
      margin: 0;
      line-height: 1.5rem;
      margin-bottom: 8px;
    }

    & .modal-description {
      margin: 0;
      font-weight: 400;
      display:flex;
      flex-direction:column;
      align-items:center;
      color: ${theme.palette.mode === 'dark' ? grey[400] : grey[800]};
    }
  `,
);

const TriggerButton = styled(Button)(
    ({ theme }) => css`
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.5;
    padding: 8px 16px;
    border-radius: 8px;
    transition: all 150ms ease;
    cursor: pointer;
    width:200px;
    margin:3rem;
    background: ${theme.palette.mode === 'dark' ? grey[900] : blue[400]};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    color: ${theme.palette.mode === 'dark' ? grey[200] : grey[900]};
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);

    &:hover {
      background: ${theme.palette.mode === 'dark' ? grey[800] : blue[200]};
      border-color: ${theme.palette.mode === 'dark' ? grey[600] : grey[300]};
    }

    &:active {
      background: ${theme.palette.mode === 'dark' ? grey[700] : grey[100]};
    }

    &:focus-visible {
      box-shadow: 0 0 0 4px ${theme.palette.mode === 'dark' ? blue[300] : blue[200]};
      outline: none;
    }
  `,
);
export default ChangePassword;
