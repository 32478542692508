import { Link, useNavigate, useParams } from "react-router-dom";
import { Box, Button, Modal, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import LineModel from "../Models/LineModel";
import linesService from "../Services/Lines";
import ParticlesBg from "particles-bg";
import Loader from "./Loader";
import adminService from "../Services/Admin";



export default function CancelLine(): JSX.Element {
    const navigate = useNavigate();
    const params = useParams();
    const [lineToDelete, setLineToDelete] = useState<LineModel>();
    const [date, setDate] = useState<Date>();
    const [isLoading, setIsLoading] = useState(true);
    const daysMap = ["ראשון", "שני", "שלישי", "רביעי", "חמישי", "שישי","שבת"];

    useEffect(() => {        
        linesService.getLineById(params.line_id)
            .then(res => {                
                adminService.getAdminByIdAsync(res.admin_id)
                    .then(adminData => {
                        setIsLoading(false);
                        if (res) {
                            if ((+res.timestamp - new Date().getTime()) < adminData.minCancelTimeMS) return;
                            setLineToDelete(res);
                            setDate((new Date(+res.timestamp)));
                        }
                    });
            });
    }, [params])

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        maxWidth: '600px',
        bgcolor: '#fff',
        boxShadow: 24,
        p: 4,
        paddingBottom: 2,
        borderRadius: '7px',
    }
    return <div className="CancelLine">
        <ParticlesBg type="circle" color="#FFD700" bg={true} />
        <Modal
            open={true}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >

            <Box sx={style}>
                {isLoading ? <Loader /> : lineToDelete ? <>
                    <Typography id="modal-modal-title" variant="h5" component="h1" style={{ margin: "3px", direction: "rtl", textAlign: 'center' }}>
                        {lineToDelete.name + " - " + lineToDelete.phone.replace("+972", "0")}
                    </Typography>
                    <br />
                    <h3 style={{ textAlign: 'center' }}>יום {daysMap[date.getDay()]}</h3>
                    <h3 style={{ textAlign: 'center' }}>{`${date.getDate()}.${(+date.getMonth() + 1)} - ${date.toTimeString().substring(0, 5)}`}</h3>
                    <Button onClick={async () => {
                        await linesService.deleteLineAsync(params.line_id);
                        navigate("/");
                    }} sx={{ margin: "1rem", padding: '0.5rem 2rem 0.5rem 2rem', borderRadius: '20px' }} variant="contained" color="error">ביטול התור</Button>
                </>
                    : <>
                        <Typography id="modal-modal-title" variant="h6" component="h1" style={{ margin: "5px", direction: "rtl", textAlign: 'center' }}>
                            לא ניתן לבטל את התור צור קשר עם בית העסק
                        </Typography>
                        <Button sx={{ margin: "1rem", padding: '0.5rem 2rem 0.5rem 2rem', borderRadius: '20px' }} variant="contained" >
                            <Link style={{ color: '#fff' }} to="/" >חזרה לדף הבית </Link>
                        </Button>
                    </>
                }
            </Box>
        </Modal>
    </div>
}