import axios from "axios";
import { JwtPayload, jwtDecode } from "jwt-decode";
// import { userLoggedOut } from "../Redux/Reducers/user.slice";
import store from "../Redux/Store";
import notify from "./Notify";
import { useNavigate } from "react-router-dom";

const jwtAxios = axios.create();

jwtAxios.interceptors.request.use(request => {

    if (store.getState().authState.admin) {

        //const decodedToken : JwtPayload = store.getState().authState.admin?.token ? jwtDecode(store.getState().authState.admin?.token) : null

        // if (decodedToken.exp < Date.now() / 1000){
        //     store.dispatch(userLoggedOut());
        //     const navigate = useNavigate();
        //     navigate('/auth/login');
        //     notify.error("Your session has expired");
        //     return;
        // }

        request.headers.set("authorization", "Bearer " + store.getState().authState.admin?.token);
    }

    return request;
});

export default jwtAxios;