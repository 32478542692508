import { Fade } from "react-awesome-reveal";
import { useAppSelector } from "../Redux/Store";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import adminService from "../Services/Admin";

function Footer() {
  const adminData = useAppSelector(state => (state.linesState.currentAdmin || state.authState.admin));
  const params = useParams();

  useEffect(() => {
    if (adminData && (document.title !== adminData.businessName)) document.title = adminData.businessName;
    if (!adminData || (adminData._id !== params._id)) adminService.getAdminByIdAsync(params._id)
      .then(res => document.title = res.businessName);

  }, [adminData, params]);

  return (
    <footer id="footer">
      <div className="row">
        <Fade triggerOnce direction="down">
          <div className="twelve columns">
            <ul className="social-links">
              <li key="whatsapp">
                <a href={"https://wa.me/" + (adminData ? adminData.phone : "")}>
                  <i className="fa fa-whatsapp"></i>
                </a>
              </li>
              <li key="instagram">
                <a href={adminData ? adminData.socials.instagram : ""}>
                  <i className="fa fa-instagram"></i>
                </a>
              </li>
              <li key="facebook">
                <a href={adminData ? adminData.socials.facebook : ""}>
                  <i className="fa fa-facebook"></i>
                </a>
              </li>
            </ul>

            <ul className="copyright">
              <li>&copy; Copyright 2024</li>
              <li>
                Designed by{" "}
                <a title="EZ Webs" href="https://www.instagram.com/ezwebs/" target="blank">
                  EZ Web Solutions
                </a>
              </li>
            </ul>
          </div>
        </Fade>

        <div id="go-top">
          <a className="smoothscroll" title="Back to Top" href="#header">
            <i className="fa fa-chevron-up"></i>
          </a>
        </div>
      </div>
    </footer>
  );
}


export default Footer;
