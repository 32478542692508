import {Fade} from "react-awesome-reveal";
import { useAppSelector } from "../Redux/Store";
import globals from "../Services/Globals";

function About () {
  const admin = useAppSelector(state=>(state.linesState.currentAdmin || state.authState.admin));
  return (
      <section id="about">
        {admin && <Fade triggerOnce duration={1000}>
          <div className="row">
            <div className="three columns">
              <img
                className="profile-pic"
                src={globals.imagesUrl+"/"+admin.logoImgName}
                alt="Profile Pic"
              />
            </div>
            <div className="nine columns main-col">
              <h2>קצת עלינו</h2>
              <p>{admin.aboutMe}</p>
              <div className="row">
                <div className="columns">
                  <ul className="social-links">
                    <li key='facebook'>
                      <a href={admin.socials.facebook}>
                        <i className='fa fa-facebook'></i>
                      </a>
                    </li>
                    <li key='whatsapp'>
                      <a href={'https://wa.me/'+admin.phone}>
                        <i className='fa fa-whatsapp'></i>
                      </a>
                    </li>
                    <li key='instagram'>
                      <a href={admin.socials.instagram}>
                        <i className='fa fa-instagram'></i>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="columns contact-details">
                  <h2>צרו קשר</h2>
                  <p className="address">
                    <span>{admin.businessName}</span>
                    <br />
                    <span>
                      {admin.address.street}
                      <br />
                      {admin.address.city}
                    </span>
                    <br />
                    <span><a href={"tel:+"+admin.phone}>{admin.phone.replace("972","0")}</a></span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Fade>}
      </section>
    );
  
}

export default About;
