import { useEffect, useState } from "react";
import "./SettingsPage.css";
import AdminModel from "../../../Models/AdminModel";
import store from "../../../Redux/Store";
import { Button, FormControl, TextField, FormControlLabel, Switch } from "@mui/material";
import adminService from "../../../Services/Admin";
import { useParams } from "react-router-dom";
import notify from "../../../Services/Notify";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {
    DndContext,
    DragEndEvent,
    MouseSensor,
    TouchSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import DraggableComponent from "../../DraggableComponent";
import DroppableComponent from "../../DroppableComponent";
import globals from "../../../Services/Globals";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const addressMapping = {
    state: "מדינה",
    city: "עיר",
    street: "רחוב",
    floor: "קומה",
    aptNum: "מספר דירה"
};

function SettingsPage(): JSX.Element {
    const [adminData, setAdminData] = useState<AdminModel>(store.getState().authState.admin);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [imagesToDelete, setImagesToDelete] = useState<string[]>([]);
    const [imagesToPost, setImagesToPost] = useState<File[]>([]);
    const [newDescription, setNewDescription] = useState<string>("");

    const params = useParams();
    //const [isDropped, setIsDropped] = useState(false);
    const [displayDelete, setDisplayDelete] = useState(false);

    useEffect(() => {
        if (!adminData || adminData._id !== params._id) {
            adminService.getAdminByIdAsync(params._id)
                .then(res => setAdminData(res));
        }
    }, []);

    const mouseSensor = useSensor(MouseSensor, {
        activationConstraint: {
            distance: 10,
        },
    });
    const touchSensor = useSensor(TouchSensor, {
        activationConstraint: {
            delay: 200,
            tolerance: 5,
        },
    });

    const sensors = useSensors(
        mouseSensor,
        touchSensor,
    );
    const messageOnOff = () => {
        setAdminData(prev => ({ ...prev, message: { ...prev.message, disabled: !prev.message.disabled } }));
    };

    const addDesc = () => {
        // const index = adminData.vacations.findIndex(v => v === vacation);
        if (adminData.descriptions.length === 7) return notify.custom("מקסימום 7 אלמנטים")
        const newArr = [...adminData.descriptions];
        newArr.push(newDescription);
        setAdminData(prev => ({ ...prev, descriptions: newArr }));
        setNewDescription("");
    }

    const removeDesc = (desc: string) => {
        if (adminData.descriptions.length < 2) return notify.custom("מינימום 1");
        const index = adminData.descriptions.findIndex(d => d === desc);
        const newArr = [...adminData.descriptions];
        newArr.splice(index, 1);
        setAdminData(prev => ({ ...prev, descriptions: newArr }));
    }
    const saveChanges = async () => {
        setIsLoading(true);
        const newArr = [...adminData.imagesNames];
        imagesToDelete.forEach(i => {
            const index = newArr.findIndex(img => img === i);
            newArr.splice(index, 1);
        });

        const updatedAdmin = { ...adminData, imagesNames: newArr }
        await adminService.updateAdminAsync(updatedAdmin, null, imagesToPost, imagesToDelete);
        setIsLoading(false);
    };
    const handleDragEnd = (event: DragEndEvent) => {
        if (event.over) {
            //setIsDropped(true);
            const draggedElement = (event.activatorEvent.target as HTMLElement).id.replace("-draggable", "");
            const droppedContainer = event.over.id.toString().replace("-container", "");

            const draggedIndex = adminData.imagesNames.findIndex(i => i === draggedElement);
            const droppedIndex = adminData.imagesNames.findIndex(i => i === droppedContainer);
            if (droppedContainer === "deletor") {
                (event.activatorEvent.target as HTMLBaseElement).hidden = true;
                if (draggedElement.includes("-new")) {
                    const index = imagesToPost.findIndex(i => i.name === draggedElement.replace("-new", ""));
                    imagesToPost.splice(index, 1);
                    return setDisplayDelete(false);
                }
                imagesToDelete.push(draggedElement);

                // const newImagesArr = [...adminData.imagesNames]
                // newImagesArr.splice(draggedIndex, 1);
                // console.log(newImagesArr);

                // setAdminData(prev => ({ ...prev, imagesNames: newImagesArr }));
                return setDisplayDelete(false);
            }
            if (draggedElement.includes("-new")) return;
            const newImagesArr = [...adminData.imagesNames];
            newImagesArr[draggedIndex] = droppedContainer;
            newImagesArr[droppedIndex] = draggedElement;
            setAdminData(prev => ({ ...prev, imagesNames: newImagesArr }));
            setDisplayDelete(false);
        }
    }
    const handleLogoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            if ((event.target.files.length + adminData.imagesNames.length + imagesToPost.length - imagesToDelete.length) > 12) return notify.custom("מקסימום 12 תמונות")
            setImagesToPost(prev => ([...prev, ...Array.from(event.target.files)]));
        }
    };
    return (
        <div className="SettingsPage">
            {adminData && <>
                <h4>שם העסק:</h4>
                <TextField
                    value={adminData.businessName}
                    onChange={(event) => setAdminData(prev => ({ ...prev, businessName: event.target.value }))}
                    label="שם העסק"
                    dir="rtl"
                    margin="dense"
                    fullWidth
                />

                <h4>הודעת פתיחה :</h4>
                <div className="openMessage">
                    <FormControlLabel
                        sx={{ direction: "ltr", margin: '5px' }}
                        control={<Switch checked={!adminData?.message.disabled} />}
                        label=":הצג הודעת פתיחה "
                        onClick={messageOnOff}
                    />
                    <TextField
                        fullWidth
                        disabled={adminData?.message.disabled}
                        multiline
                        value={adminData.message.value}
                        onChange={(event) => setAdminData(prev => ({ ...prev, message: { ...prev.message, value: event?.currentTarget?.value } }))}
                    />
                </div>





                {/* <h4>תיאור:</h4>
                {adminData.descriptions.map((desc, index) => (
                    <div className="description-line" key={index}>
                        <TextField
                            value={desc}
                            onChange={(event) => {
                                const newDescriptions = [...adminData.descriptions];
                                newDescriptions[index] = event.target.value;
                                setAdminData(prev => ({ ...prev, descriptions: newDescriptions }));
                            }}
                            label={`תיאור ${index + 1}`}
                            dir="rtl"
                            margin="dense"
                            fullWidth
                        />
                        <Button onClick={() => removeDesc(desc)} sx={{ margin: "1rem", padding: '0.5rem 1rem 0.5rem 1rem' }} type="submit" variant="contained" color="error">מחיקה</Button>

                    </div>
                ))} */}
                {/* 
                <div className="description-line" >
                    <TextField
                        value={newDescription}
                        onChange={(event) => setNewDescription(event.target.value)}
                        label="תיאור חדש"
                        dir="rtl"
                        margin="dense"
                        fullWidth
                    />
                    <Button disabled={!newDescription} onClick={addDesc} sx={{ margin: "1rem", padding: '0.5rem 1rem 0.5rem 1rem' }} type="submit" variant="contained" color="success">הוספה</Button>
                </div> */}

                <h4>עליי:</h4>
                <TextField
                    value={adminData.aboutMe}
                    onChange={(event) => setAdminData(prev => ({ ...prev, aboutMe: event.target.value }))}
                    label="עליי"
                    dir="rtl"
                    margin="dense"
                    multiline
                    fullWidth
                />
                <h4>תיק עבודות :</h4>
                <div className="portfolioEdit">
                    <Button
                        variant="contained"
                        component="label"
                    >
                        הוספת תמונה &nbsp;<CloudUploadIcon />
                        <input
                            type="file"
                            multiple
                            accept="image/*"
                            onChange={handleLogoChange}
                            hidden
                        />
                    </Button>

                    <span>גרור לעריכה</span>
                    <div className="imagesEditor">
                        <DndContext onDragStart={() => { setDisplayDelete(true) }} onDragEnd={handleDragEnd} sensors={sensors}>
                            {adminData.imagesNames.map((image, index) =>

                                <DraggableComponent key={index} id={image} component={
                                    <DroppableComponent id={image + "-container"} component={
                                        <img loading="eager" draggable={false} width="140" id={image + "-draggable"} alt={image} src={globals.imagesUrl + "/" + image} />
                                    } />
                                } />

                            )}
                            {imagesToPost.map(i => <DraggableComponent key={i.name} id={i.name} component={
                                <DroppableComponent id={i.name + "-container"} component={
                                    <img loading="eager" width="140" id={i.name + "-draggable-new"} alt={i.name} src={URL.createObjectURL(i)} />
                                } />
                            } />)

                            }
                            {<DroppableComponent id="deletor" component={
                                <Button
                                    sx={{ padding: "2rem", m: '2rem 0', transition: 'scale 0.3s ease', scale: (displayDelete ? 1 : 0) }}
                                    size="large"
                                    fullWidth
                                    variant="outlined"
                                    color="error"
                                    startIcon={<DeleteForeverIcon fontSize="large" />}
                                >
                                    &emsp;מחיקה
                                </Button>
                            } />
                            }
                        </DndContext>
                    </div>
                </div>
                <h4>מחיר תור :</h4>
                <FormControl sx={{ mb: 2 }}>
                    <TextField
                        value={adminData.incomePerLine}
                        onChange={event => { setAdminData(prev => ({ ...prev, incomePerLine: +event.target?.value })) }}
                        label="מחיר תור "
                        dir="ltr"
                        margin="dense"
                        required
                        type="number"
                        id="income-input"
                        variant="outlined"
                    />
                </FormControl>
                <h4>כתובת:</h4>
                {Object.entries(adminData.address).map(([key, value]) => (
                    <TextField
                        key={key}
                        value={value}
                        onChange={(event) => setAdminData(prev => ({ ...prev, address: { ...prev.address, [key]: event.target.value } }))}
                        label={addressMapping[key as ("state")]}
                        dir="rtl"
                        margin="dense"
                    />
                ))}

                <h4>טלפון:</h4>
                <TextField
                    value={adminData.phone}
                    onChange={(event) => setAdminData(prev => ({ ...prev, phone: event.target.value }))}
                    label="טלפון"
                    dir="ltr"
                    margin="dense"
                />

                <h4>רשתות חברתיות:</h4>
                {Object.entries(adminData.socials).map(([key, value]) => {
                    if (key === "tiktok") return null;
                    else return (
                        <TextField
                            key={key}
                            value={value}
                            onChange={(event) => setAdminData(prev => ({ ...prev, socials: { ...prev.socials, [key]: event.target.value } }))}
                            label={key}
                            dir="ltr"
                            margin="dense"
                            fullWidth
                        />
                    )
                })}
            </>}

            <div className="form-actions-div">
                <Button
                    disabled={isLoading}
                    onClick={saveChanges}
                    sx={{ margin: "1rem", padding: '0.5rem 2rem', borderRadius: '20px' }}
                    type="submit"
                    variant="contained"
                    color="success"
                >
                    שמירה
                </Button>
                <Button
                    onClick={() => adminService.getAdminByIdAsync(params._id).then(res => setAdminData(res))}
                    sx={{ margin: "1rem", padding: '0.5rem 2rem', borderRadius: '20px' }}
                    type="submit"
                    variant="contained"
                    color="warning"
                >
                    ביטול
                </Button>
            </div>
        </div>
    );
}

export default SettingsPage;
