import React, { useState } from "react";
import { Typography, Link, IconButton } from "@mui/material";
import { ContentCopy, DoneAll } from '@mui/icons-material';
import "./CopyToClipboard.css";
import { Zoom } from "react-awesome-reveal";

const CopyToClipboard = (props: { link: string }) => {
    const [isCopied, setIsCopied] = useState(false);

    const handleCopy = () => {
        navigator.clipboard
            .writeText(props.link)
            .then(() => {
                setIsCopied(true);
                setTimeout(() => {
                    setIsCopied(false);
                }, 2000)
            })
            .catch((err) => console.error("Failed to copy text: ", err));
    };


    return (
        <div className="CopyToClipboard" >
            <Typography variant="subtitle1">
                הלינק שלך
            </Typography>
            <Link
                href={props.link}
                target="_blank"
                rel="noopener noreferrer"
                underline="hover"
            >
                {window.innerWidth < 550 ? props.link.substring(0, 30) + "..." : props.link}
            </Link>
            <IconButton
                title="Copy"
                disabled={isCopied}
                onClick={handleCopy}>
                {isCopied ? <Zoom duration={250}> <DoneAll color="info" /></Zoom>
                    :
                    <Zoom duration={250}><ContentCopy /></Zoom>}
            </IconButton>

        </div>
    );
};

export default CopyToClipboard;
