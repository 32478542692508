import { StaticDatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import AdminModel from "../Models/AdminModel";
import Loader from "./Loader";

interface DatePickerProps {
    adminData: AdminModel;
    epochDate: number;
    setDate: React.Dispatch<React.SetStateAction<number>>;
}

function DatePickerComponent(props: DatePickerProps) {
    return (
        <StaticDatePicker
            shouldDisableDate={(day) => {
                if (!props.adminData?.workingDays[day.day()]) return true;
                for (let i = 0; i < props.adminData.vacations.length; i++) {
                    const start = +props.adminData.vacations[i].split("-")[0];
                    const end = +props.adminData.vacations[i].split("-")[1];
                    return (day.valueOf() >= start) && (day.valueOf() <= end);
                }
            }}
            disablePast
            showDaysOutsideCurrentMonth
            maxDate={dayjs().add(1, 'month')}
            view="day"
            sx={{
                '.MuiDateCalendar-root': {
                    color: '#000000',
                    borderRadius: '18px',
                    borderWidth: '1px',
                    borderColor: '#c0d6e4',
                    border: '2px solid #c0d6e4',
                    backgroundColor: '#F0F8FF',
                },
                '.MuiTypography-overline': { display: 'none' },
                '.MuiPickersCalendarHeader-root': { direction: 'ltr' }
            }}
            loading={!props.adminData}
            renderLoading={() => <Loader />}
            value={dayjs(props.epochDate)}
            dayOfWeekFormatter={(day: string) => {
                switch (day) {
                    case "א׳": return "ראשון"
                    case "ב׳": return "שני"
                    case "ג׳": return "שלישי"
                    case "ד׳": return "רביעי"
                    case "ה׳": return "חמישי"
                    case "ו": return "שישי"
                    case "ש׳": return "שבת"
                }
            }}
            onChange={(newValue) => {
                if (newValue) props.setDate(newValue.valueOf());
            }}
        />


    )
}
export default DatePickerComponent;
