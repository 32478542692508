import { useEffect, useState } from "react";
import store, { useAppSelector } from "../../../Redux/Store";
import linesService from "../../../Services/Lines";
import AdminLineCard from "../AdminLineCard/AdminLineCard";
import { useParams } from "react-router-dom";
import LinesInfo from "../LinesInfo/LinesInfo";
import adminService from "../../../Services/Admin";
import "./LinesList.css";
import Loader from "../../Loader";


function LinesList(): JSX.Element {
    const adminData = useAppSelector(state => (state.authState.admin));
    const [linesData, setLinesData] = useState(store.getState().linesState.lines);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const params = useParams();

    useEffect(() => {
        if (!adminData || (linesData.length === 0) || (linesData[0]?.admin_id !== adminData._id)) adminService.getAdminByIdAsync(params._id)
            .then(res => {
                linesService.getAllLinesAsync(res._id, true)
                    .then(lines => {
                        setIsLoading(false);
                        setLinesData(lines)
                    });
            });
        else setIsLoading(false);
        const interval = setInterval(() => {
            linesService.getAllLinesAsync(adminData._id, true);
        }, 180000);
        return () => clearInterval(interval);
    }, [])

    return <div className="LinesList gradient-bg">
        <LinesInfo setLines={setLinesData} />
        {isLoading ? <Loader /> : linesData?.length > 0 ? <>
            {/* <h2 className="lines-list-h2">התורים שלך</h2> */}
            <div className="list-container">
                {[...linesData].sort((a, b) => (+a.timestamp) - (+b.timestamp)).map(l => <AdminLineCard key={l._id} line={l} />)}
            </div>
        </>
            :
            <h2 className="lines-list-h2"> אין תורים לתאריך הנבחר</h2>
        }
    </div>
}

export default LinesList;