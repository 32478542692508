import React from 'react';
import { useDroppable } from '@dnd-kit/core';

interface Props {
    component: JSX.Element;
    id: string;
}

function DroppableComponent(props: Props): JSX.Element {
    const { isOver, setNodeRef } = useDroppable({
        id: props.id,
    });
    const style = isOver ? {
        transform: "scale(1.2)"
    }
        :
        {
            transform: "scale(1)" /* Initial scale */
        }


    return (
        <div ref={setNodeRef} style={{ transition: "transform 0.3s ease", ...style }}>
            {props.component}
        </div>
    );
}
export default DroppableComponent;