class AdminModel {
    public _id: string;
    public username: string;
    public name: string;
    public phone: string;
    public password: string;
    public token: string;
    public message: {
        value: string;
        disabled: boolean;
    };
    public socials: {
        instagram: string;
        tiktok: string;
        facebook: string;
    };
    public vacations: string[];
    public workingDays: string[];
    public imagesNames: string[];
    public minutesPerLine: number;
    public minCancelTimeMS: number;
    public incomePerLine: number;
    public logoImgName: string;
    public businessName: string;
    public descriptions: string[];
    public aboutMe: string;
    public address: {
        state: string;
        city: string;
        street: string;
        floor: string;
        aptNum: string;
    };

    constructor(admin: AdminModel) {
        if (admin._id) this._id = admin._id;
        if (admin.username) this.username = admin.username;
        if (admin.name) this.name = admin.name;
        if (admin.phone) this.phone = admin.phone;
        if (admin.password) this.password = admin.password;
        if (admin.token) this.token = admin.token;
        if (admin.message) this.message = admin.message;
        if (admin.socials) this.socials = admin.socials;
        if (admin.vacations) this.vacations = admin.vacations;
        if (admin.workingDays) this.workingDays = admin.workingDays;
        if (admin.imagesNames) this.imagesNames = admin.imagesNames;
        if (admin.minutesPerLine) this.minutesPerLine = admin.minutesPerLine;
        if (admin.incomePerLine) this.incomePerLine = admin.incomePerLine;
        if (admin.logoImgName) this.logoImgName = admin.logoImgName;
        if (admin.businessName) this.businessName = admin.businessName;
        if (admin.descriptions) this.descriptions = admin.descriptions;
        if (admin.aboutMe) this.aboutMe = admin.aboutMe;
        if (admin.address) this.address = admin.address;
        if (admin.minCancelTimeMS) this.minCancelTimeMS = admin.minCancelTimeMS;
    }

    public static convertToFormData(admin: AdminModel, logoImage: File = null, images: File[] = null, imagesToDelete: string[] = null): FormData {
        const formData = new FormData();
        if (admin._id) formData.append("_id", admin._id);
        if (admin.username) formData.append("username", admin.username);
        formData.append("name", admin.name);
        formData.append("phone", admin.phone);
        if (images) images.forEach(i => {
            formData.append("images", i);
        })
        if (logoImage) formData.append("logoImage", logoImage);
        if (imagesToDelete) formData.append("imagesToDelete", JSON.stringify(imagesToDelete));
        if (admin.password) formData.append("password", admin.password);
        formData.append("vacations", JSON.stringify(admin.vacations));
        formData.append("workingDays", JSON.stringify(admin.workingDays));
        formData.append("imagesNames", JSON.stringify(admin.imagesNames));
        formData.append("message", JSON.stringify(admin.message));
        formData.append("socials", JSON.stringify(admin.socials));
        formData.append("descriptions", JSON.stringify(admin.descriptions));
        formData.append("address", JSON.stringify(admin.address));
        formData.append("minutesPerLine", admin.minutesPerLine.toString());
        formData.append("minCancelTimeMS", admin.minCancelTimeMS.toString());
        formData.append("incomePerLine", admin.incomePerLine.toString());
        formData.append("businessName", admin.businessName);
        formData.append("logoImgName", admin.logoImgName);
        formData.append("aboutMe", admin.aboutMe);
        return formData;
    }
}

export default AdminModel;