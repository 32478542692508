import { useEffect, useState } from "react";
import About from "./About";
import AlertMessage from "./AlertMessage";
import Contact from "./Contact";
import Footer from "./Footer";
import Header from "./Head";
import LinePicker from "./LinePicker";
import Portfolio from "./Portfolio";
import SpeedDialComponent from "./SpeedDialComponent";
import ErrorBoundary from "./ErrorBoundary/ErrorBoundary";





function Home() {
    const [alert, setAlert] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setAlert(true);
        }, 2000)
    }, [])
    return (
        <div className="Home">
            <ErrorBoundary>
                {alert && <AlertMessage />}
                <Header />
                <About />
                <Portfolio />
                <LinePicker />
                <Contact />
                <Footer />
                <SpeedDialComponent />
            </ErrorBoundary>
        </div>
    );

}
export default Home;