import { Box, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { Settings, ListAlt, History, CalendarMonth, Face, MeetingRoom, HelpOutline } from '@mui/icons-material';

import * as React from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';

interface IListItem {
    name: string;
    url: string;
    icon: JSX.Element
}
const NavList: IListItem[] = [
    {
        name: "רשימת תורים",
        url: "lines",
        icon: <ListAlt />
    },
    {
        name: "זמנים וחופשות",
        url: "schedule",
        icon: <CalendarMonth />
    },
    {
        name: "הסטוריה",
        url: "history",
        icon: <History />
    },
    {
        name: "הגדרות",
        url: "settings",
        icon: <Settings />
    }

]
const userNavList: IListItem[] = [
    {
        name: "חשבון",
        url: "account",
        icon: <Face />
    },
    {
        name: "עזרה",
        url: "help",
        icon: <HelpOutline />
    },
    {
        name: "התנתקות",
        url: "logout",
        icon: <MeetingRoom />
    }


]
export default function HeaderDrawer() {
    const [open, setOpen] = React.useState(false);

    const toggleDrawer = (newOpen: boolean) => () => {
        setOpen(newOpen);
    };
    const DrawerList = (
        <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
            <List>
                {NavList.map((item) => (
                    <Link to={item.url} key={item.name} >
                        <ListItem disablePadding >
                            <ListItemButton>
                                <ListItemIcon children={item.icon} />
                                <ListItemText primary={item.name} />
                            </ListItemButton>
                        </ListItem>
                    </Link>
                ))}
            </List>
            <Divider />
            <List>
                {userNavList.map((item) => (
                    <Link to={item.url} key={item.name} >
                        <ListItem disablePadding >
                            <ListItemButton>
                                <ListItemIcon children={item.icon} />
                                <ListItemText primary={item.name} />
                            </ListItemButton>
                        </ListItem>
                    </Link>
                ))}
            </List>
        </Box >
    );

    return (
        <div>
            <IconButton
                size="large"
                aria-label="menu"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={toggleDrawer(true)}
                color="inherit"
            >
                <MenuIcon />
            </IconButton>
            <Drawer anchor='right' open={open} onClose={toggleDrawer(false)}>
                {DrawerList}
            </Drawer>
        </div>
    );
}