import "./AdminLayout.css"
import { Route, Routes } from "react-router-dom";
import SettingsPage from "../SettingsPage/SettingsPage";
import LinesList from "../LinesList/LinesList";
import NotFoundPage from "../../NotFoundPage";
import AdminHeader from "../AdminHeader/AdminHeader";
import Footer from "../../Footer";
import HistoryPage from "../HistoryPage/HistoryPage";
import Logout from "../../AuthArea/Logout/Logout";
import ScheduleAndVaca from "../ScheduleAndVaca/ScheduleAndVaca";
import HelpPage from "../HelpPage/HelpPage";
import AccountPage from "../AccountPage/AccountPage";

export default function AdminLayout(): JSX.Element {

    return <div className="AdminLayout">
        <header><AdminHeader /></header>
        <main>
            <Routes>
                <Route path="/settings" element={<SettingsPage />} />
                <Route path="/lines" element={<LinesList />} />
                <Route path="/history" element={<HistoryPage />} />
                <Route path="/help" element={<HelpPage />} />
                <Route path="/account" element={<AccountPage />} />
                <Route path="/schedule" element={<ScheduleAndVaca />} />
                <Route path="/logout" element={<Logout />} />
                <Route path="*" element={<NotFoundPage />} />
            </Routes>

        </main>
        <Footer />
    </div>
}