import { useState, useEffect } from "react";
import "./AccountPage.css";
import AdminModel from "../../../Models/AdminModel";
import store from "../../../Redux/Store";
import { Button, TextField } from "@mui/material";
import adminService from "../../../Services/Admin";
import { useParams } from "react-router-dom";
import globals from "../../../Services/Globals";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ChangePassword from "./ChangePassword/ChangePassword";
import CopyToClipboard from "../../CopyToClipboard/CopyToClipboard";

function AccountPage(): JSX.Element {
    const [adminData, setAdminData] = useState<AdminModel>(store.getState().authState.admin);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [logoImg, setLogoImg] = useState<File | null>(null);
    const params = useParams();

    useEffect(() => {
        if (!adminData || adminData._id !== params._id) {
            adminService.getAdminByIdAsync(params._id)
                .then(res => setAdminData(res));
        }

    }, [params._id, adminData]);

    const handleLogoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            setLogoImg(event.target.files[0]);
        }
    };

    const saveChanges = async () => {
        setIsLoading(true);
        const res = await adminService.updateAdminAsync(adminData, logoImg);
        if (res) setIsLoading(false);
    };

    return (
        <div className="AccountPage">
            {adminData && (
                <>
                    <CopyToClipboard link={window.location.origin+"/"+adminData._id} />
                    <div className="uploadLogoDiv">
                        <h4>לוגו :</h4>
                        <img
                            style={{ margin: '1rem' }}
                            width="200px"
                            src={logoImg ? URL.createObjectURL(logoImg) : (globals.imagesUrl + "/" + adminData.logoImgName)}
                            alt="Logo Pic"
                        />
                        <Button
                            variant="contained"
                            component="label"
                        >
                            בחירת תמונה &nbsp;<CloudUploadIcon />
                            <input
                                type="file"
                                multiple={false}
                                accept="image/*"
                                onChange={handleLogoChange}
                                hidden
                            />
                        </Button>
                    </div>

                    <div className="userDetails">
                        <TextField
                            disabled
                            value={adminData.username}
                            onChange={(event) => setAdminData(prev => ({ ...prev, username: event.target.value }))}
                            label="שם משתמש"
                            dir="rtl"
                            margin="dense"
                        />
                        <ChangePassword admin_id={adminData._id} />
                    </div>

                    <div className="form-actions-div">
                        <Button
                            disabled={isLoading}
                            onClick={saveChanges}
                            sx={{ margin: "1rem", padding: '0.5rem 2rem', borderRadius: '20px' }}
                            type="submit"
                            variant="contained"
                            color="success"
                        >
                            שמירה
                        </Button>
                        <Button
                            onClick={() => adminService.getAdminByIdAsync(params._id).then(res => { setAdminData(res); setLogoImg(null); })}
                            sx={{ margin: "1rem", padding: '0.5rem 2rem', borderRadius: '20px' }}
                            type="submit"
                            variant="contained"
                            color="warning"
                        >
                            ביטול
                        </Button>
                    </div>
                </>
            )}
        </div>
    );
}

export default AccountPage;
