import AdminModel from "../Models/AdminModel";
import { adminLoggedIn } from "../Redux/Reducers/auth.slice";
import { setCurrentAdmin } from "../Redux/Reducers/lines.slice";
import store from "../Redux/Store";
import globals from "./Globals";
import jwtAxios from "./JwtAxios";
import notify from "./Notify";
import usersService from "./Users";

class AdminService {


    public async getAdminByIdAsync(_id: string): Promise<AdminModel> {
        try {
            if (store.getState().linesState.currentAdmin && store.getState().linesState.currentAdmin._id === _id) return store.getState().linesState.currentAdmin;
            if (store.getState().authState.admin && store.getState().authState.admin._id === _id) return store.getState().authState.admin;
            const response = await jwtAxios.get<AdminModel>(globals.adminUrl + "/" + _id);
            store.dispatch(setCurrentAdmin(response.data));
            // store.dispatch(setCurrentAdmin(response.data));
            return response.data;
        }
        catch (err) {
            notify.error(err);
            return null;
        }

    }
    public async updateAdminAsync(admin: AdminModel, logoImage: File = null, imagesToPost: File[] = null, imagesToDelete: string[] = null): Promise<AdminModel> {
        try {            
            const response = await jwtAxios.post<AdminModel>(globals.adminUrl, AdminModel.convertToFormData(admin, logoImage, imagesToPost, imagesToDelete));
            response.data.token = store.getState().authState.admin.token;
            store.dispatch(setCurrentAdmin(response.data));
            store.dispatch(adminLoggedIn(response.data));
            usersService.SaveUserLocal(response.data);
            notify.custom("השינויים נשמרו");
            return response.data;
        }
        catch (err) {
            notify.error(err);
            return null;
        }

    }


}
const adminService = new AdminService();
export default adminService;