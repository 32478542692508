import { useEffect } from 'react';
import './App.css';
import ReactGA from "react-ga";
import { LocalizationProvider } from '@mui/x-date-pickers';
import 'dayjs/locale/he';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Home from './Components/Home';
import NotFoundPage from './Components/NotFoundPage';
import ProtectedRoute from './Services/ProtectedRoute';
import Login from './Components/AuthArea/Login/Login';
import CancelLine from './Components/CancelLine';
import AdminHomePage from './Components/AdminArea/AdminLayout/AdminLayout';

function App() {

  // useEffect(() => {
    // document.title ="123456"
    // ReactGA.initialize("UA-0000000-1");
    // ReactGA.pageview(window.location.pathname);
  // }, [])

  return (
    <div className="App">
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="he">
        <Router>
          <Routes>
            <Route path="/"  element={<Navigate to="/664dabc4d01d2a90b3bab623" />} />
            
            <Route path="/:_id" element={<Home />} />

            <Route path="/auth/login" element={<Login />} />

            <Route path="/cancel/:line_id" element={<CancelLine />} />

            <Route path="/:_id/manage/*" element={<ProtectedRoute redirectPath="/auth/login" protectedComponent={<AdminHomePage />} />} />

            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </Router>
      </LocalizationProvider>
    </div>
  );
}

export default App;
